<template>
    <div class="item-type">
        {{ ITEM.parseTypeToLabel(type) }}
    </div>
</template>
<script>
import * as ITEM from '@items/utils/constants'

export default {
    props: {
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            ITEM
        }
    }
}
</script>
<style lang="scss" scoped>
.item-type {
    display: inline-flex;
    font-size: 0.8rem;
    color: var(--type-active);
}
</style>